/* Apply this style to the table container */
.table-container {
    overflow-x: auto; /* Enable horizontal scrolling if needed */
    width: 100%;
    max-width: 100%; /* Ensure it doesn't exceed the parent container */
  }
  
  /* Apply this style to the modal content */
  .modal-content {
    padding: 20px; /* Add padding to the modal content for spacing */
  }


  /* Add these styles to your report.css or a separate CSS file */

.table-container {
  position: relative;
}

.pagination {
  margin-top: 10px; /* Add some spacing between the table and pagination */
  display: flex;
  justify-content: center;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 18px;
}

.loader-container {
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  height: 100%; /* Make the container fill the available height */
}

.text-center {
  text-align: center;
}

.close-icon{
right: 1;
}



