@media (prefers-reduced-motion: no-preference) {
  .spinner {
    animation: spinner infinite 2s ease-in-out;
  }
}
  
@keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }